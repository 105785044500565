@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,900&display=swap');

.btn-success {
  background-color: #f00;
  border-color: #f00;
  color: #fff;
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #f00;
  font-weight: 700 !important;
}
.sosmed {
  border-radius: 100%;
  background-color: #f00;
}

#developer {
  background-color: #eee;
  color: #035937;
}

#developer a {
  color: #035937 !important;
  font-weight: bold;
}

#developer a:hover {
  color: #fff !important;
  font-weight: bold;
}

#chatSekarang {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

html,
body {
  user-select: none;
}
