.testimonials .carousel {
  max-width: 650px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.testimonials .carousel .carousel-item {
  color: #999;
  text-align: center;
  overflow: hidden;
  /* min-height: 340px; */
}
.testimonials .carousel .carousel-item a {
  color: #eb7245;
}
.testimonials .carousel .img-box {
  width: 145px;
  height: 145px;
  margin: 0 auto;
  border-radius: 50%;
}
.testimonials .carousel .img-box img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}
.testimonials .carousel .testimonial {
  padding: 30px 0 10px;
}
.testimonials .carousel .overview {
  text-align: center;
  padding-bottom: 5px;
}
.carousel .overview b {
  color: #333;
  font-size: 15px;
  text-transform: uppercase;
  display: block;
  padding-bottom: 5px;
}
.testimonials .carousel .star-rating i {
  font-size: 18px;
  color: #ffdc12;
}
.testimonials .carousel-control-prev,
.testimonials .carousel-control-next {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #999;
  text-shadow: none;
  top: 4px;
}
.testimonials .carousel-control-prev i,
.testimonials .carousel-control-next i {
  font-size: 20px;
  margin-right: 2px;
}
.testimonials .carousel-control-prev {
  left: auto;
  right: 40px;
}
.testimonials .carousel-control-next i {
  margin-right: -2px;
}
.testimonials .carousel .carousel-indicators {
  bottom: 15px;
}
.testimonials .carousel-indicators li,
.testimonials .carousel-indicators li.active {
  width: 11px;
  height: 11px;
  margin: 1px 5px;
  border-radius: 50%;
}
.testimonials .carousel-indicators li {
  background: #e2e2e2;
  border: none;
}
.testimonials .carousel-indicators li.active {
  background: #888;
}
